// Header
.header-camp {
  position: fixed;

  .wrap {
    .left {
      .logo-img {
        .logo {
          height: 70px;
          width: auto;

          @include media("<=phoneH") {
            height: 48px;
          }
        }
      }
    }

    .center {
      flex: 1;

      .menu-item {
        // font-family: "Inter", sans-serif;
        color: #ffff;
        font-size: 16px;
        font-weight: 400;

        &:hover,
        &.active {
          @include anim;
          cursor: pointer;
          background: linear-gradient(93.21deg, #B2FF00 6.06%, #532ecd 132.54%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      @include media("<=tablet") {
        display: none;
      }
    }

    .right {
      .btn-stack {
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 14px;
        // font-family: "Inter", sans-serif;
      }

      .social {
        .icon {
          height: 18px;
          width: 18px;
          cursor: pointer;

          &:hover {
            @include anim;

            svg {
              path {
                fill: #B2FF00;
              }
            }
          }
        }
      }

      @include media("<=tablet") {
        display: none;
      }
    }

    .menu-icon {
      height: 18px;
      width: 18px;
      cursor: pointer;
      display: none;

      @include media("<=tablet") {
        display: flex;
      }
    }
  }
}

// Sidebar
.sidebar {
  position: fixed;
  top: 0;
  right: -250px; // Adjust this based on your sidebar width
  width: 250px; // Adjust as needed
  height: 100%;
  background-color: black; // Adjust background color
  transition: right 0.3s ease; // Smooth transition
  z-index: 100; // Ensure it overlays content

  &.open {
    right: 0; // Move into view
  }
}
.header-container {
  display: flex;
  justify-content: center;
  /* Center the header */
}

.header-camp {
  margin: 10px auto;
  /* Center the header with auto margins */
  max-width: 98%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.wrapWidth {
  padding: 0 20px;
  /* Adjust padding to suit your layout */
}

.logo {
  border-radius: 15px;  /* Adjust the value as needed */
}