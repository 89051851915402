.staking-pass-page {
  width: 98%;
  // height: 100%;
  // min-height: 768px;

  // padding: 120px 0;
  margin: 0 auto 0;
  border-radius: 15px;
  background-color: #000;

  @include media("<=tablet") {
      padding: 0;
      padding-right: 20px;
      padding-left: 20px;
      padding-bottom: 50px;
    }

  .wrap {
    @include media("<=tablet") {
      flex-direction: column;
    }

    .left {}

    .right {
      .title {
        font-size: 28px;
        font-weight: 600;
        background: linear-gradient(93.21deg, #B2FF00 6.06%, #532ecd 132.54%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .price-lbl {}

      .title2 {}
    }
  }
}


.wrapWidth{
  width: 98%;
  margin: 20px auto 20px;
  border-radius: 15px;
  background-color: #000;
}