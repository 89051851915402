// Footer
.footer-comp {
  background: #000;
  .top-sec {
    .wrap {
    }
  }
  .copy-right {
    color: #fff;
    font-size: 14px;
    // font-family: "Inter", sans-serif;
    background: linear-gradient(95.86deg, #d114ba 25.94%, #5b0ccc 119.59%);
    padding: 14px 2px;
    text-align: center;
  }
}
.Link {
  display: flex;
  justify-content: center;
  /* Horizontally center */
  align-items: center;
  /* Vertically center */
  height: 100%;
  /* Make sure the container has a height if you want vertical centering */
}
.logo-footer{
  border-radius: 15px;
  margin-bottom: 8px;
  width: 25%;
  height: auto;
}
.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;   /* Slightly bigger size for better visibility */
  height: 60px;  /* Circular size */
  border-radius: 50%; /* Make the div circular */
  background-color: #fff;  /* White background for the circle */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  cursor: pointer; /* Add a pointer cursor for interactivity */
}

.social-icon:hover {
  background-color: #f3f4f6; /* Light grey background on hover */
  transform: scale(1.05); /* Slightly scale up the icon on hover for effect */
}

/* Style the social media icons */
.icon-footer {
  width: 70%;    /* Icon will take 70% of the parent container's width */
  height: 70%;   /* Icon will take 70% of the parent container's height */
  object-fit: contain; /* Ensure icons maintain their aspect ratio */
  border-radius: 50%; /* Ensure the image stays within the circular div */
}

/* Adjust icon size for smaller screens */
@media (max-width: 768px) {
  .social-icon {
    width: 50px;  /* Smaller size for mobile */
    height: 50px;
  }
  .icon-footer {
    width: 60%; /* Adjust icon size for smaller screens */
    height: 60%;
  }
}
