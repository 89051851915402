.lading-page {
  width: 100%;
  height: 100%;
}
.white-background{
  background: #fff;
}
.solid-green-background{
  background-color: #B2FF00
}

.bg-liner-green{
  // font-family: "Inter", sans-serif;
  background: linear-gradient(93.21deg, #B2FF00 6.06%, #333333 132.54%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 44px;
  font-weight: 500;
  @include media("<=tablet") {
    font-size: 28px;
  }
}
.text-solid-green{
  color: #B2FF00
}
.hero-section {
  // margin-top: 120px;
  margin: 120px auto 20px;
  // min-height: 100vh;
  width: 98%;
  // background-image: url("../../../public/images/home-bg.webp");
  background: #000;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 80px 0;
  @include media("<=tablet") {
      padding: 50px 0 !important;
    }
  .wrap {
    @include media(">tablet") {
      display: flex;
      // padding: 50px;
      @include media("<=large") {
          padding: 50px !important;
        }
      
        @include media("<desktop") {
          padding: 50px !important;
      
        }
    }
    @include media("<=tablet") {
      flex-direction: column;
    }

    .left-side {
      @include media(">tablet") {
        flex: 0 0 60%;
      }
        /* Set width to 60% */
      @include media("<=tablet") {
        align-items: center;
      }

      .slug {
        font-size: 55px;
        font-weight: 500;
        // line-height: 46px;
        color: #fff;

        span {
          // background: linear-gradient(93.21deg, #B2FF00 6.06%, #333333 132.54%);
          background: linear-gradient(93.21deg, #B2FF00 6.06%, #333333 132.54%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        @include media("<=tablet") {
          // font-size: 20px;
          line-height: 60px;
          text-align: center;
        }
      }

      .home-desc {
        font-size: 18px;
        font-weight: 400;
        color: #fff;
        // font-family: "Inter", sans-serif;

        @include media("<=tablet") {
          font-size: 16px;
          text-align: center;
        }
      }

      .btn-white-p {
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        // font-family: "Inter", sans-serif;
        width: max-content;
      }
    }

    .right-side {
      @include media(">tablet") {
          flex: 0 0 40%;
        }
      .box-presale {
        width: 400px;
        min-height: 400px;
        // border: 1.5px solid #B2FF00;
        // border: 1.5px solid #B2FF00;
        // border-image-source: linear-gradient(
        //   145.47deg,
        //   #B2FF00 -3.83%,
        //   #5234ce 106.17%
        // );
        border-image-slice: 1;
        border-radius: 10px;
        img {
            max-width: 250% !important;
            /* Make image responsive */
            height: 250%;
            /* Maintain aspect ratio */
            /* You can set a specific size if needed, e.g. */
            /* width: 80%; */
            /* height: 80%; */
          }

        .title {
          padding: 16px 12px;
          font-size: 24px;
          font-weight: 500;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
        }

        .sub-title {
          padding: 14px 12px;
          font-size: 16px;
          font-weight: 200;
          color: #000;
          text-align: center;
          text-transform: uppercase;
          border-top: 1px solid #B2FF00;
          border-bottom: 1px solid #B2FF00;
        }

        .box-body {
          padding: 16px 12px;

          .numb {
            font-size: 24px;
            font-weight: 500;
            color: #000;
            text-align: center;
            // font-family: "Inter", sans-serif;
          }

          .desc {
            font-size: 14px;
            font-weight: 300;
            color: #000;
            text-align: center;
            text-transform: uppercase;
          }

          .title2 {
            font-size: 24px;
            // background: linear-gradient(93.21deg, #B2FF00 6.06%, #333333 132.54%);
            background: #B2FF00;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .desc2 {
            font-size: 16px;
            font-weight: 300;
            color: #000;
            text-align: center;
            text-transform: uppercase;
          }

          .social-btns {
            .btn {
              width: 100%;

              &:first-child {
                background: #fff;
                color: #000;
              }
            }
          }
        }
      }
    }
  }
}

.about-us-section {
  margin: 0px auto 20px;
  border-radius: 15px;
  // min-height: 768px;
  width: 98%;
  padding: 40px 0;
  background: #000;

  .wrap {
    .main-block {
      @include media("<=tablet") {
        flex-direction: column-reverse;
      }

      .left-side {
        .about-img {
          height: 300px;
          @include media("<=tablet") {
            height: 150px;
          }
        }
      }

      .right-side {
        .title {
          background: linear-gradient(93.21deg, #B2FF00 6.06%, #333333 132.54%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 44px;
          font-weight: 500;
          @include media("<=tablet"){
            font-size: 28px;
          }
        }

        .line {
          border: 0.4px solid #B2FF00;
        }

        .desc {
          font-size: 18px;
          text-align: justify;
          // font-weight: 300;
          color: white;
          // font-family: "Inter", sans-serif;
          @include media("<=tablet") {
            font-size: 15px;
          }
        }

        .btn-dtail {
          width: max-content;
        }
      }
    }

    .token-box {
      width: 100%;
      min-height: 400px;
      padding: 60px 60px;
      border-radius: 16px;
      background: linear-gradient(180deg,
          rgba(209, 20, 186, 0) 0%,
          rgba(209, 20, 186, 0.39) 100%);

      @include media("<=tablet") {
        flex-direction: column;
        gap: 40px;
        width: 100%;
        padding: 20px;
      }

      .box-left {
        .slug {
          font-size: 26px;
          font-weight: 500;
          color: #fff;

          @include media("<=tablet") {
            font-size: 18px;
            margin-bottom: 12px;
          }
        }

        .detail-box {
          @include media("<=tablet") {
            gap: 10px;

          }

          .detail-left {
            @include media("<=tablet") {
              width: 100%;
              flex: 1;
            }
          }

          .detail-right {}
        }
      }

      .box-right {
        .fav-icon {
          height: 200px;
          width: 200px;
        }
      }
    }
  }
}

.generative-ai-section {
  min-height: 560px;
  width: 100%;
  background-image: url("../../../public/images/GenerativeAi-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 60px 0;

  .wrap {
    .about-block {
      @include media("<=tablet") {
        flex-direction: column;
      }

      .chart-img {
        width: 280px;
        height: 280px;
      }

      .bars-list {
        .bar-item {
          .lbl {
            color: #fff;
            font-size: 14px;
          }

          .bar {
            height: 6px;
            // width: 100%;
            border-radius: 8px;
            background: #5b9bd5;
          }

          &:nth-child(2) {
            .bar {
              background: #ed7d31;
            }
          }

          &:nth-child(3) {
            .bar {
              background: #a5a5a5;
            }
          }

          &:nth-child(4) {
            .bar {
              background: #4472c4;
            }
          }

          &:nth-child(5) {
            .bar {
              background: #ffc000;
            }
          }
        }
      }
    }
  }
}

.our-team-section {
  .wrap {
    .team-grid {
      .user-card {
        height: 280px;
        width: 100%;
        // background-color: #e2e2e2;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 12px;
        padding: 16px;
      }
    }
  }
}

.faqs-section {
  width: 100%;
  min-height: 568px;
  padding: 60px 0;
  background: #000;

  .wrap {
    @include media("<=tablet") {
      flex-direction: column;
    }

    .meta-side {
      .faqs-list {
        .faq {
          padding: 12px 0;
          border-bottom: 1px solid #B2FF00;

          &:first-child {
            // border-top: 1px solid #B2FF00;
          }

          .faq-meta {
            .question {
              color: #fff;
              font-size: 18px;
              font-weight: 500;
            }

            .icon {
              height: 34px;
              width: 34px;
              border-radius: 10px;
              background: linear-gradient(97.05deg,
                  #B2FF00 16.22%,
                  #552c77 132.4%);
            }
          }

          .answer {
            display: none;

            &.open {
              display: flex;
            }
          }
        }
      }
    }

    .image-side {
      .img {
        height: 450px;
      }
    }
  }
}

.brands {
  // margin-top: 120px;
  margin: 0px auto 20px;
  // min-height: 100vh;
  width: 98%;
  // background-image: url("../../../public/images/home-bg.webp");
  background: #000;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 10px 0;
  
  .find_us{
    font-size: 18px;
      font-weight: 400;
      color: #fff;
      // font-family: "Inter", sans-serif;
      text-align: center;
      margin: 10px;
      @include media("<=tablet") {
        font-size: 16px;
      }
  }
}

// 
.road-map-section{
  margin: 0 auto 20px;
  width: 98%;
  background: #000;
  border-radius: 15px;
}

.tokenomics-section{
  margin: 0 auto 20px;
  width: 98%;
  background: #000;
  border-radius: 15px;
  padding: 30px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 60px;
  @include media("<=tablet") {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.inner-sec {
  margin-top: 30px !important;
  margin-bottom: 50px !important;
}

.faq-section{
  margin: 0 auto 20px;
  width: 98%;
  background: #000;
  border-radius: 15px;
  padding: 30px;
    padding-left: 120px;
    padding-right: 120px;
    padding-bottom: 60px;
  
    @include media("<=tablet") {
      padding-left: 20px;
      padding-right: 20px;
    }
}

.footer-sec{
  margin: 0 auto 20px;
  width: 98%;
  background: #000;
  border-radius: 15px;
}

.token-add{
  font-size: 14px;
}

.why-us {
  font-size: '35px';
  text-decoration: underline;
}

.why-us-below{
  color: #fff;
  font-size: 18px;
}

// Main Section
.why-choose-us-section {
  margin: 0 auto 20px;
  width: 98%;
  background: #000;
  border-radius: 15px;
  padding: 60px 0;
  // text-align: center;

  .wrap {
    max-width: 1200px;
    margin: 0 auto;
    .why-us {
      background: linear-gradient(93.21deg, #B2FF00 6.06%, #333333 132.54%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 44px;
      font-weight: 500;
      
    
      @include media("<=tablet") {
        font-size: 28px;
      }
    }
    .line {
      border: 0.4px solid #B2FF00;
    }

    // .why-us {
    //   font-size: 35px;
    //   text-decoration: underline;
    //   color: #fff;
    //   margin-bottom: 20px;
    // }

    .why-us-below {
      font-size: 18px;
      color: #fff;
      margin-bottom: 30px;

      p {
        line-height: 1.8;
      }
    }

    .features {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;

      .feature {
        background-color: #1f1f1f;
        padding: 20px;
        border-radius: 8px;
        width: 280px;
        box-sizing: border-box;
        text-align: left;
        transition: all 0.3s ease;

        &:hover {
          background-color: #333;
          transform: translateY(-5px);
        }

        .feature-title {
          font-size: 20px;
          font-weight: 500;
          color: #b2ff00;
          margin-bottom: 15px;
        }

        .feature-description {
          font-size: 16px;
          color: #fff;
          line-height: 1.6;
        }
      }
    }
  }
}

// Mobile Responsive Adjustments
@media (max-width: 768px) {
  .why-choose-us-section {
    padding: 40px 20px;
  }

  .wrap {
    .features {
      flex-direction: column;
      gap: 20px;
    }

    .feature {
      width: 100%;
      padding: 15px;
    }
  }
}

