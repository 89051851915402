// Side Bar
.sidebar-s {
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999;
  height: 100%;
  inset: 0;
  overflow: hidden;

  .side-block {
    width: 280px;
    background-color: #000000;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    bottom: 0;
    transition: all 0.3s cubic-bezier(0.9, 0.32, 0.46, 1.18) 0s;

    &::-webkit-scrollbar {
      width: 5px;
    }

    @include media(">=phone", "<=480px") {
      width: 70%;
    }

    &.show {
      right: 0;

      .body {
        overflow-y: hidden;
      }
    }

    &.hide {
      right: -450px;
      /* Adjusted to hide from the right */
    }

    .hdr {
      padding: 14px;
      width: 100%;
      border-bottom: 1.4px solid #ffffff;

      .hdr-tag {
        font-weight: 700;
        font-size: 18px;
        line-height: 50px;
        text-align: center;
        text-transform: capitalize;
        color: #ffffff;
      }

      .icon-close {
        height: 20px;
        cursor: pointer;

        svg {
          height: 30px;
          width: 30px;

          path {
            stroke: #fff;
            fill: #fff;
          }
        }
      }
    }

    .items {
      padding: 20px 16px;

      .list-item {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 14px;
        padding: 8px 0;
        border-radius: 4px;

        .li {
          width: 70%;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          margin-right: 30px;
          align-items: start;
          @include anim;
          color: #fff;

          @include media("<=desktop") {
            font-size: 15px;
            margin-right: 28px;
            text-align: start;
          }
        }

        &.active {
          background: #ffffff;

          .li {
            color: #5c5c5c;
          }
        }
      }
    }

    .sidebar-footer {
      padding: 14px;
      width: 100%;

      .action {
        .btn {
          padding: 8px 18px;
          background: #f1f1f1;
          border-radius: 30px;

          .btn-lbl {
            margin-left: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
          }
        }
      }
    }
  }
}

.sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  backdrop-filter: blur(5px);
  /* Adjust blur amount as needed */
  z-index: 100;
  /* Ensure it’s below the sidebar but above other content */
  display: none;
  /* Hidden by default */
}

.sidebar-backdrop.show {
  display: block;
  /* Show when sidebar is open */
}

.sidebar-s {
  z-index: 99999;
  /* Keep the sidebar above the backdrop */
}