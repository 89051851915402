/* src/ChatUI.css */
.chat-window {
    background-color: white;
    background-color: #ffffff;
    padding: 120px 0 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    /* Adjust margin if needed */
}

.messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    height: 300px;
    border-bottom: 1px solid #e0e0e0;
}

.user {
    text-align: right;
    margin: 5px 0;
    color: #007bff;
}

.bot {
    text-align: left;
    margin: 5px 0;
    color: #28a745;
}

.input-area {
    display: flex;
    padding: 10px;
}

input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 5px;
}

button {
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}