.white-background-staking-page{
  background-color: #ffffff;
  padding: 120px 0 20px;
}

.staking-page {
  margin: 0 auto 0;
  width: 98%;
  border-radius: 15px;
  background-color: #000;
  padding: 50px 0;
  @include media("<=tablet") {
    padding: 0;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 50px;
  }
  .wrap {
    .main-block {
      @include media("<=tablet") {
        flex-direction: column;
      }

      .left {
        @include media("<=tablet") {
          width: 100%;
        }

        .tabs {
          background: #1c1c1c;
          border: 4px solid #444;
          padding: 6px;
          border-radius: 10px;

          .tab-item {
            width: 100%;
            font-size: 16px;
            color: #fff;
            padding: 14px 18px;
            cursor: pointer;
            @include anim;
            text-align: center;

            &:first-child {
              border-radius: 14px 0 0 14px;
            }

            &:nth-child(2) {
              border-left: 1px solid #b2ff00;
              border-right: 1px solid #b2ff00;
            }

            &:last-child {
              border-radius: 0 14px 14px 0;
            }

            &.active {
              background: linear-gradient(97.05deg,
                  #b2ff00 16.22%,
                  #333333 132.4%);
            }
          }
        }

        .stack-box {
          border-radius: 12px;
          border: 1px solid #b2ff00;
        }
      }

      .right {
        @include media("<=tablet") {
          width: 100%;
        }

        .url {
          background: linear-gradient(93.21deg, #b2ff00 6.06%, #333333 132.54%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}